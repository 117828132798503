// 导入 createPinia
import { createPinia } from 'pinia'
import { App } from 'vue'

// 创建 导出 pinia
const pinia = createPinia()

/**
 * 导出 setup Store
 */
export const setup = (app: App): App => {
  app.use(pinia)
  return app
}
