<template>
  <div class="">
    <div>
      <slot name="header"></slot>
    </div>
    <el-table
      :data="dataList"
      border
      style="width: 100%">
      <!-- 选中 -->
      <el-table-column v-if="showSelectColumn" type="selection" align="center" width="50" />
      <!-- 索引 -->
      <el-table-column v-if="showIndexColumn" type="index" align="center" width="60" label="序号" />
      <!-- 遍历表格 -->
      <template v-for="(item) in propList" :key="item.prop">

        <el-table-column v-bind="item" align="center" show-overflow-tooltip>
          <!-- 作用域插槽 -->
          <template v-if="item.slotName" #default="scope">
            <!-- 插槽反向传值 :row="scope.row" -->
            <slot :name="item.slotName" :row="scope.row">
              {{ scope.row[item.prop ?? ''] }}
            </slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div>
      <template v-if="showFooter">
        <div class="footer flex">
          <div>
            <slot name="footer"></slot>
          </div>
          <el-pagination
            :currentPage="pageInfo.page"
            :page-size="pageInfo.limit"
            :total="totalCount"
            :page-sizes="pageSizes"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { ElTable, ElTableColumn } from 'element-plus'
import { IProp } from './types'

export interface IPageInfo {
  page: number,
  limit: number
}

const props = withDefaults(
  defineProps<{
    dataList: Array<any>
    propList: IProp[]
    showIndexColumn: boolean
    showSelectColumn: boolean
    showFooter: boolean,
    pageInfo: IPageInfo,
    totalCount: number
    pageSizes: number[]
  }>(),{
    showIndexColumn: false,
    showSelectColumn: false,
    showFooter: false,
    pageInfo: (() => {
        return {
          page: 1,
          limit: 10
        }
    }),
    totalCount: 0,
    pageSizes: () => [10, 20, 30, 40]
  }
)

const handleSizeChange = (limit: number) => {
  emits('update:pageInfo', { ...props.pageInfo, limit })
}
const handleCurrentChange = (page: number) => {
  emits('update:pageInfo', { ...props.pageInfo, page })
}

// 定义事件
const emits = defineEmits<{
  (e: 'update:pageInfo', value: any): void
}>()

</script>

<style lang="less" scoped>
::v-deep .el-table .cell.el-tooltip  {
   white-space: normal;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
</style>
