export const path = {
  // 登录
  login: '/admin/login',

  // 验证码
  captcha: '/admin/captcha',

  // 员工
  employee: '/admin/employee',

  // 项目
  project: '/admin/project',

  // 审批
  review: '/admin/record',

  // 退出登录
  logout: '/admin/logout',

  // 修改密码
  update: '/admin/update',

    // 类型
  type: '/admin/type',

    // 注册
  register: '/admin/register',

      // 统计
  counter: '/admin/counter'
}

/**
 * 路由
 */
export const xyqRouter = {
  // 登录
  login: '/login',
  // 注册
  register: '/register',
    // 审批
  home: '/main/home/home',
  // 审批
  review: '/main/review/review',
  // 项目
  project: '/main/project/project',
  // 员工
  employee: '/main/employee/employee',
  // 类型
  type: '/main/type/type'
}

/**
 * 错误码
 */
export const errorCode = {
  // token过期
  tokenExpired: 70002,
  // 没有token
  noToken: 70001
}
