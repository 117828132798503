// 校验规则
export const rules = {
  account: [
    {
      required: true,
      message: '帐号是必填',
      trigger: 'blur'
    },
    {
      pattern: /^[a-zA-Z0-9]{5,}$/,
      message: '帐号长度必须是5位以上',
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      message: '密码是必填',
      trigger: 'blur'
    },
    {
      pattern: /^[a-zA-Z0-9]{5,}$/,
      message: '密码长度必须是5位以上',
      trigger: 'blur'
    }
  ]
  ,
  captcha: [
    {
      required: true,
      message: '验证码是必填',
      trigger: 'blur'
    },
    {
      pattern: /^[a-zA-Z0-9]{4}$/,
      message: '验证码必须是4位',
      trigger: 'blur'
    }
  ]
}
