
import { ITable } from "@/components/table"

export const employeeTable: ITable = {
  propList: [
    { prop: 'name', label: '姓名', minWidth: 100},
    { prop: 'phone', label: '手机号', minWidth: 140},
    { prop: 'gender', label: '性别', minWidth: 60, slotName: 'gender' },
    { prop: 'role', label: '角色', minWidth: 80, slotName: 'role' },
    { prop: 'resigned', label: '状态', minWidth: 80, slotName: 'resigned' },
    { prop: 'createTime', label: '创建时间', minWidth: 130 },
    { prop: 'updateTime', label: '更新时间', minWidth: 130 },
    { label: '操作', minWidth: 240, slotName: 'handle' }
  ],
  showIndexColumn: true,
  showSelectColumn: false,
  showFooter: true,
}
