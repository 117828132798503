import { defineStore } from 'pinia'
import { queryRequest, IReviewQuery, IReview } from '@/service/main/review'
import { queryRequest as titleQueryRequest } from '@/service/type/type'
import { ElMessage } from 'element-plus'

export interface IHeader {
  // 费用名称
  name: string
  // 金额
  amount: number
  // 总数
  total: number
}

export const useReviewStore = defineStore('review', {
  state: () => ({
    reviews: [] as IReview[],
    total: 0,
    headers: [] as IHeader[]
  }),
  actions: {
    /**
     * 查询审批列表
     * @param data 分页数据
     */
    query(data: IReviewQuery) {
      // 发起请求
      queryRequest(data)
        .then((result) => {
          // 保存数据
          this.reviews = result.data as IReview[]
          this.total = result.total
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async queryTitles() {
      const result = await titleQueryRequest({ page: 1, limit: 100 })
      const headers = result.data.map((type) => {
        return {
          name: type.name,
          total: 0,
          amount: 0
        }
      })
      this.headers = headers
    },

    /**
     * 导出员工数据
     * @returns 数据
     */
    exportEmployee() {
      const headers = this.headers
      headers.forEach((header) => {
        header.amount = 0
        header.total = 0
      })

      const details = this.reviews
      if (details.length <= 0) {
        ElMessage.error({ message: '没有数据可以导出' })
        return []
      }

      // const values = [] as Array<Array<string>>
      const values = [] as any[][]

      for (let index = 0; index < details.length; index++) {
        const detail = details[index];

        if (0 != detail.state) {
          continue;
        }
        const data = [] as any[]
        data.push(detail.projectName)
        data.push(0) // 单价

        headers.forEach((header) => {
          const items = detail.items.filter((item) => item.name === header.name)

          if (items.length > 0) {
            data.push(items[0].amount)
            header.total += items[0].total ?? 0
            header.amount += items[0].amount
          } else {
            data.push(0)
            header.total += 0
            header.amount += 0
          }
        })
        values.push(data)
      }

      // details.forEach((detail) => {
      //   const data = [] as string[]
      //   data.push(detail.projectName)
      //   data.push('0') // 单价

      //   headers.forEach((header) => {
      //     const items = detail.items.filter((item) => item.name === header.name)

      //     if (items.length > 0) {
      //       data.push(items[0].amount + '')
      //       header.total += items[0].total ?? 0
      //       header.amount += items[0].amount
      //     } else {
      //       data.push('0')
      //       header.total += 0
      //       header.amount += 0
      //     }
      //   })
      //   values.push(data)
      // })

      // 排序
      values.sort((v1, v2) => { return v1[0].localeCompare(v2[0])})

    const sortValues = [] as any[][]

    let index = 0
      let projectName = ''
      values.forEach((item => {
        const name = item[0].trim()
        if (name !== projectName && 0 != name.length) {
          sortValues.push([])
          if (0 != index) {
            sortValues.push([])
          }
        }

        sortValues.push(item)
        projectName = name
        index += 1
      }))

      sortValues.push([])
      sortValues.push([])

      const total = ['合计', 0]
      headers.forEach((header) => {
        total.push(header.amount)
      })

      sortValues.push(total)
      sortValues.push([])
      sortValues.push(['申报人：', '', '审核', '', '确认'])
      return sortValues
    },

    /**
     * 导出项目数据
     */
    exportProject() {
      const headers = this.headers
      headers.forEach((header) => {
        header.amount = 0
        header.total = 0
      })

      const details = this.reviews
      if (details.length <= 0) {
        ElMessage.error({ message: '没有数据可以导出' })
        return []
      }

      details.forEach((detail) => {
        headers.forEach((header) => {
          const items = detail.items.filter((item) => item.name === header.name)

          if (items.length > 0) {
            header.amount += items[0].amount
            header.total += header.amount

          } else {
            header.total += 0
            header.amount += 0
          }
        })
      })

      const record = details[0]

      const project = [record.projectName, 0]
      const counter = ['数量小记', 0]
      const total = ['费用合计', 0]
      headers.forEach((header) => {
        project.push(header.amount + '')
        counter.push(header.amount + '')
        total.push(header.total + '')
      })

      const values = [] as any[][]
      values.push(project)
      values.push(counter)
      values.push(total)
      return values
    }
  }
})
