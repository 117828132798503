<script lang="ts" setup>

import * as XLSX from 'xlsx'
import { format } from 'date-fns/format'
import { reviewTable } from './config/review-config'
import { ref, reactive, watch, computed, onMounted } from 'vue'
import xTable from '@/components/table'
import { useReviewStore } from '@/stores/review/review'
import { IReviewQuery } from '@/service/main/review'
import { ElMessage } from 'element-plus'

// 添加/更新项目
const data = reactive<IReviewQuery>({
  // 页码
  page: 1,
  // 每页数量
  limit: 10,
  // 日期 2024-08-01,2024-08-31
  dates: undefined,
  // 员工名称
  name: undefined,
  // 项目名称
  project: undefined
})

const pagesSizes = ref<number[]>([10, 30, 50, 100, 200])
const dates = ref<Date[]>([])
const name = ref('')
const project = ref('')

const handleExportEmployee = () => {

  const nickname = name.value.trim()
  if (nickname.length <= 0) {
    ElMessage.error({ message: '请输入员工姓名搜索数据' })
    return
  }

  if (dates.value.length < 1) {
    ElMessage.error({ message: '请输入导出数据的时间范围' })
    return
  }

  const tableData = store.exportEmployee()
  if (tableData.length <= 0) { return }
  const tabelName = `${nickname}职员`
  handleExport(tabelName, tableData)
}

/**
 * 导出项目数据
 */
const handleExportProject = () => {
  const projectName = project.value.trim()
  if (projectName.length <= 0) {
    ElMessage.error({ message: '请输入项目名称搜索数据' })
    return
  }

  if (dates.value.length < 1) {
    ElMessage.error({ message: '请输入导出数据的时间范围' })
    return
  }

  const tableData = store.exportProject()
  if (tableData.length <= 0) { return }

  const tabelName = `${projectName}项目`
  handleExport(tabelName, tableData)
}

const handleExport = (tabelName: string, tableData: string[][]) => {
  const header = ([] as string[]).concat(store.headers.map((header) => header.name))
  header.unshift('单价')
  header.unshift('项目名称')
  header.unshift('序号')

    // 将数据和表头合并，添加序号
    const dataWithHeader = [header, ...tableData.map((item, index) => {
      let data = [] as string[]
      data.push(index + 1 + '')
      data = data.concat(item)
      return data
    })];

  // 时间
    const startDate = dates.value[0]
    const endDate = dates.value[1]
    const start = format(startDate, 'yyyy-MM-dd')
    const end = format(endDate, 'yyyy-MM-dd')

  const name = `数据汇总表 -（${tabelName} - ${start} ~ ${end}）`

  const worksheet = XLSX.utils.json_to_sheet(dataWithHeader)
    // 插入标题
  XLSX.utils.sheet_add_aoa(worksheet, [[name]], { origin: 'A1' });
  // 合并单元格，标题占据A1到F1
  worksheet['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: header.length, r: 0 } }];
  // 调整行高（可选），可以调整A1的行高
  worksheet['!rows'] = [{ hpt: 24 }]; // 设置A1的行高为24点
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, worksheet, tabelName)
  XLSX.writeFile(wb, name + '.xlsx')
}

// 搜索
const handleSearch = () => {
  const nickname = name.value.trim()
  const projectName = project.value.trim()

  if (0 == nickname.length && 0 == projectName.length) {
    ElMessage.error({ message: '请输入你要查询的员工或项目' })
    return
  }

  if (nickname.length > 0) {
    data.name = nickname
  }

  if (projectName.length > 0) {
    data.project = projectName
  }

  if (dates.value.length <= 0) {
    ElMessage.error({ message: '请输入你要查询的时间范围' })
    return
  }

  if (dates.value.length > 1) {
    const startDate = dates.value[0]
    const endDate = dates.value[1]
    const start = format(startDate, 'yyyy-MM-dd HH:mm:ss')
    const end = format(endDate, 'yyyy-MM-dd HH:mm:ss')
    data.dates = start + ',' + end
  }

  data.page = pageInfo.value.page
  data.limit = pageInfo.value.limit

  store.query(data)
}

/**
 * 重置
 */
const handleReset = () => {
  dates.value = []
  name.value = ''
  project.value = ''

  data.dates = undefined
  data.name = undefined
  data.project = undefined

  store.query({ ...pageInfo.value })
}

/**
 * 清除时间选择
 */
 const handleClear = () => {
  dates.value = []
  data.dates = ''
}

// 分页数据
const pageInfo = ref({
  page: 1,
  limit: 10
})

watch(pageInfo, () => {
  store.query({ ...pageInfo.value })
})


// store
const store = useReviewStore()

// 计算
const reviews = computed(() => store.reviews)
const total = computed(() => store.total)

// 挂载完成请求数据
onMounted(() => {
  store.query({ ...pageInfo.value })
  store.queryTitles()
})

</script>

<template>
  <div class="table">

    <div class="search">
      <div class="name">
        <span>姓名</span>
        <el-input v-model="name" type="input" placeholder="请输入员工姓名" size="large">
        </el-input>
      </div>

      <div class="project">
        <span>项目名称</span>
        <el-input v-model="project" type="input" placeholder="请输入项目名称" size="large">
        </el-input>
      </div>

      <div class="dates">
        <span>日期</span>
        <el-date-picker @clear="handleClear" v-model="dates" size="large" type="dates" placeholder="请选择要查询的日期范围" />
      </div>

      <div>
        <el-button @click="handleSearch">搜索</el-button>
        <el-button @click="handleReset">重置</el-button>
      </div>

    </div>

    <x-table :data-list="reviews" :total-count="total" :page-sizes="pagesSizes" v-model:pageInfo="pageInfo"
      v-bind="reviewTable">

      <template #footer>
        <div class="header">
          <el-button @click="handleExportEmployee">导出员工数据</el-button>
          <el-button @click="handleExportProject">导出项目数据</el-button>
        </div>
      </template>


      <!-- 收支详情 -->
      <template #items="scope">
        <div class="items">
          <template v-for="item in scope.row.items" :key="item.id">
            <div>{{ item.name }}：{{ item.amount }}{{ item.unit }}</div>
          </template>
        </div>
      </template>

      <template #state="scope">
        <!-- 审批状态 0：同意 1：拒绝 2：待审批 3：撤回 -->
        <div v-if="scope.row.state == 0" style="color: green;">
          已通过
        </div>
        <div v-if="scope.row.state == 1" style="color: red;">
          已拒绝
        </div>
        <div v-if="scope.row.state == 2">
          待审批
        </div>
      </template>

      <template #level="scope">
        <!-- 审批等级 0:审批完成 1:总经理审批 2:项目经理审批 -->

        <div v-if="scope.row.level == 0" style="color: green;">
          审批完成
        </div>
        <div v-if="scope.row.level == 1">
          待 总经理审 批
        </div>
        <div v-if="scope.row.level == 2">
          待 项目经理 审批
        </div>
      </template>

    </x-table>
  </div>
</template>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 20px 10px 20px;

  .name {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: 30px;

  }

  .project {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: 30px;
  }

  .dates {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-right: 30px;
  }

  span {
    width: 90px;
    text-align: end;
    font-size: 14px;
    margin-right: 10px;
  }
}

.table {

  .header {
    padding: 10px;
  }

  .items {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
</style>
