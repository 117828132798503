import { App } from 'vue'

// element 样式
import 'element-plus/dist/index.css'

// 导入组件
import {
  // ElConfigProvider,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  // ElCheckbox,
  ElHeader,
  ElAside,
  ElMain,
  ElContainer,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElImage,
  ElIcon,
  ElScrollbar,
  ElSelect,
  ElOption,
  // ElRow,
  // ElCol,
  ElTable,
  ElTableColumn,
  // ElSwitch,
  ElDialog,
  // ElRadioGroup,
  ElRadio,
  // ElUpload,
  ElPagination,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu

} from 'element-plus'

// 组件
const components = [
  // ElConfigProvider,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
  // ElCheckbox,
  ElHeader,
  ElAside,
  ElMain,
  ElContainer,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElImage,
  ElIcon,
  ElScrollbar,
  ElSelect,
  ElOption,
  // ElRow,
  // ElCol,
  ElTable,
  ElTableColumn,
  // ElSwitch,
  ElDialog,
  // ElRadioGroup,
  ElRadio,
  // ElUpload,
  ElPagination,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu
]

// 注册组件
function useElement(app: App): void {
  // 遍历组件 注册
  for (const cpn of components) {
    const name = cpn.name ?? ''
    app.component(name, cpn)
  }
}

// 导出组册方法
export default useElement
