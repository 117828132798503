import { defineStore } from 'pinia'

import { path } from '@/utils/constants'
import { XYQRequest } from '@/service'
import { ElMessage } from 'element-plus'

export interface ICounter {
  // 项目数量
  projects: number

  // 员工数量
  employees: number

  // 类型数量
  types: number

  // 项目经理待审核数量
  mreviews: number

  // 总经理待审核数量
  greviews: number
}

export const useHomeStore = defineStore('home', {
  state: () => ({
    counter: {} as ICounter
  }),
  actions: {
    /**
     * 统计
     */
    homeCounter() {
      XYQRequest.get<ICounter>({
        url: path.counter
      })
        .then((result) => {
          this.counter = result.data
        })
        .catch((error) => {
          ElMessage.error({ message: error.message })
        })
    }
  }
})
