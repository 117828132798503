import axios from "axios"
import { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'

import { ElMessage } from 'element-plus'
import { errorCode } from '@/utils/constants'
import router from "@/router"
import storage, { Keys } from '@/utils/storage'
import { xyqRouter } from "@/utils/constants"

// import { LoadingInstance } from 'element-plus/lib/components/loading/src/loading'

interface InterceptorHooks {
  // 请求拦截器
  requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig
  // 请求错误拦截器
  requestInterceptorCatch?: (error: any) => any

  // 响应拦截器
  responseInterceptor?: (response: AxiosResponse) => AxiosResponse
  // 响应错误拦截器
  responseInterceptorCatch?: (error: any) => any
}

interface RequestConfig extends AxiosRequestConfig {
  showLoading?: boolean
  interceptorHooks?: InterceptorHooks
}

/**
 * 请求结果
 */
export interface IResult<T> {
  code: number,
  message: string
  data: T
  pages: number,
  total: number,
}


interface IResponse<T> {
  data: IResult<T>
}

class Request {
  config: AxiosRequestConfig
  // interceptorHooks?: InterceptorHooks
  // showLoading: boolean
  // loading?: LoadingInstance
  instance: AxiosInstance

  constructor(options: RequestConfig) {
    this.config = options
    // this.interceptorHooks = options.interceptorHooks
    // this.showLoading = options.showLoading ?? true
    this.instance = axios.create(options)

    // 拦截器
    this.instance.interceptors.request.use((config) => {
    const token = storage.getCache(Keys.token)

      if (token) {
        // 在请求头 添加 token
        config.headers['Token'] = token
      }
      return config
    })
  }

  request<T>(config: RequestConfig): Promise<IResult<T>> {

    // console.log('url: ',config.url, 'data: ', config.data);

    return new Promise((resolve, reject) => {
      this.instance.request<any, IResponse<T>>(config)
        .then((response) => {
          const result = response.data
          const code = result.code

          if(code === 0) {
            resolve(result)
            // console.log('resolve data', result)
            // console.log('resolve data', response)

          } else {

            if (code === errorCode.tokenExpired || code === errorCode.noToken) {
              storage.clear()
              router.replace(xyqRouter.login)
            }

            ElMessage.error({ message: result.message })
            reject(result)
           // console.log(' error data', result)
          }
        })
        .catch((error) => {
         // console.log('request error ', error)
          // ElMessage.error({ message: error.message })
          reject(error)
        })
      })
  }

  get<T>(config: RequestConfig): Promise<IResult<T>> {
    return this.request({ ...config, method: 'GET' })
  }

  post<T>(config: RequestConfig): Promise<IResult<T>> {
    return this.request({ ...config, method: 'POST' })
  }

  delete<T>(config: RequestConfig): Promise<IResult<T>> {
    return this.request({ ...config, method: 'DELETE' })
  }

  put<T>(config: RequestConfig): Promise<IResult<T>> {
    return this.request({ ...config, method: 'PUT' })
  }
}

export default Request
