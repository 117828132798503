import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Main from '@/views/main/main.vue'
import Login from '@/views/login/login.vue'
// import Register from '@/views/register/register.vue'

import storage, { Keys } from '@/utils/storage'
import { xyqRouter } from '@/utils/constants'

import Home from '@/views/main/home/home.vue'
import Review from '@/views/main/review/review.vue'
import Project from '@/views/main/project/project.vue'
import Employee from '@/views/main/employee/employee.vue'
import Type from '@/views/main/type/type.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: xyqRouter.home
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/not-found/not-found.vue')
  },
  {
    path: xyqRouter.home,
    name: 'Main',
    component: Main,
    children: [
      {
        path: xyqRouter.home,
        name: 'Home',
        component: Home
      },
      {
        path: xyqRouter.review,
        name: 'Review',
        component: Review
      },
      {
        path: xyqRouter.project,
        name: 'Project',
        component: Project
      },
      {
        path: xyqRouter.employee,
        name: 'Employee',
        component: Employee
      },
      {
        path: xyqRouter.type,
        name: 'Type',
        component: Type
      }
    ]
  },
  {
    path: xyqRouter.login,
    name: 'Login',
    component: Login
  }
  // ,
  // {
  //   path: xyqRouter.register,
  //   name: 'Register',
  //   component: Register
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === xyqRouter.login || to.path === xyqRouter.register) {
    next()
  }

  if (to.path !== xyqRouter.login) {
    const token = storage.getCache(Keys.token)
    if (!token) {
      next(xyqRouter.login)
    }
  }

  next()
})

export default router
