import router from '@/router'
import { defineStore } from 'pinia'
import { ILoginData, IUpdateData } from '@/views/login/types'
import {
  loginRequest,
  logoutRequest,
  updateRequest
} from '@/service/login/login'

import storage, { Keys } from '@/utils/storage'
import { xyqRouter } from '@/utils/constants'
import { ElMessage } from 'element-plus'

export const useLoginStore = defineStore('login', {
  state: () => ({}),
  actions: {
    /**
     * 登录事件
     * @param payload 登录数据
     */
    async login(payload: ILoginData) {
      // 登录
      loginRequest(payload)
        .then((result) => {
          // 保存数据
          storage.setCache(Keys.token, result.data.token)
          storage.setCache(Keys.user, result.data.user)

          // 路由跳转
          router.push(xyqRouter.home)
        })
        .catch((error) => {
          console.log('error', error)

          // ElMessage.error({ message: error.message })
        })
    },

    /**
     * 退出登录
     */
    logout() {
      logoutRequest()
        .then((result) => {
          ElMessage.success({ message: result.message })
          storage.clear()
          // 路由跳转
          router.push(xyqRouter.login)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 修改密码
     * @param payload 密码数据
     */
    update(payload: IUpdateData) {
      updateRequest(payload)
        .then((result) => {
          ElMessage.success({ message: result.message })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
})
