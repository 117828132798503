const Keys = {
  token: 'token',
  user: 'user'
}

class LocalStorage {
  /**
   * 本地保存
   * @param key 键
   * @param value 值
   */
  setCache(key: string, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  /**
   * 获取信息
   * @param key 键
   * @returns 保存的对象
   */
  getCache(key: string) {
    const value = window.localStorage.getItem(key)
    if (value) {
      return JSON.parse(value)
    }
  }

  /**
   * 清缓存
   */
  clear() {
    window.localStorage.clear()
  }
}

/**
 * 导出 本地存储
 */
export default new LocalStorage()
/**
 * 导出 本地存储 Key
 */
export { Keys }
