<script lang="ts" setup>
import { ElDialog, ElMessageBox } from 'element-plus'
import { ref, reactive, computed, watch, onMounted } from 'vue'

import xTable from '@/components/table'

import { useProjectStore } from '@/stores/project/project'
import { projectTable } from './config/project-config'
import { IDialogItem } from '@/types'
import { IProjectData } from './types'
import { IProject } from '@/service/main/project'

const show = ref<boolean>(false)
const modalRef = ref<InstanceType<typeof ElDialog>>()

// 添加/更新项目
const data = reactive<IProjectData>({
  id: undefined,
  // 项目经理
  managerId: '',
  // 项目名称
  name: '',
  // 排序【越小越前面】
  sort: 0
})

/**
 * 表单数据
 */
const forms: IDialogItem[] = [
  {
    type: 'input',
    field: 'name',
    label: '项目名称',
    placeholder: '请输入项目名称'
  },
  {
    type: 'select',
    field: 'managerId',
    label: '项目经理',
    placeholder: '请选择项目经理'
  },
  {
    type: 'number',
    field: 'sort',
    label: '排序',
    placeholder: '请输入排序【越小越前面】'
  }
]

/**
 * 添加项目
 */
const handleCreate = () => {
  store.queryManager()
  show.value = true
}

/**
 * 更新
 * @param row 更新的数据
 */
const handleUpdate = (row: IProject) => {
  const { id, managerName, name, sort } = row

  data.id = id
  data.managerId = managerName
  data.name = name
  data.sort = sort

  handleCreate()
}

const handlerRemove = (row: IProject) => {
  ElMessageBox.confirm(
  `您确定要删除 ${row.name} 吗？`,
  '温馨提示',
  {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    center: true,
  }
)
  .then(() => {
    store.remove(row.id)
  }).catch(() => {
    console.log('close')
  })
}

/**
 * 确认
 */
const handleConfirm = () => {
  let managers = store.managers;
  managers = managers.filter((manager) => manager.name === data.managerId)
  if (managers.length <= 0) {
    return
  }

  const manager = managers[0]
  data.managerId = manager.id
  const { id, managerId, sort, name } = data

  if (id !== undefined) {
    store.update({ id, managerId, sort, name })

  } else {
    store.save({ managerId, sort, name })
  }

  handleClose()
}

/**
 * 取消
 */
const handleClose = () => {
  show.value = false
  data.id = undefined
  data.managerId = ''
  data.name = ''
  data.sort = 0
}

/**
 * 分页数据
 */
const pageInfo = ref({
  page: 1,
  limit: 10
})

watch(pageInfo, () => {
  store.query({ ...pageInfo.value })
})

// 计算属性
const store = useProjectStore()
const projects = computed(() => store.projects)
const total = computed(() => store.total)

// 订阅事件
store.$subscribe((_mutation, state) => {
  const managers = state.managers
  if (managers.length <= 0) { return }

  // 员工转选项
  const options = managers.map((manager) => {
    const option = {
      label: manager.name,
      value: manager.id,
    }
    return option
  })

  // 添加选项
  forms.forEach((form) => {
    if (form.type === 'select') {
      form.options = options
    }
  })
})

// 挂载完成 请求数据
onMounted(() => {
  store.query({ ...pageInfo.value })
})

</script>

<template>
  <div>
    <x-table :data-list="projects" :total-count="total" v-model:pageInfo="pageInfo" v-bind="projectTable">
      <template #header>
        <div class="header">
          <el-button @click="handleCreate">添加项目</el-button>
        </div>
      </template>


      <template #handle="scope">
        <div>
          <el-button @click="handleUpdate(scope.row)">编辑</el-button>
          <el-button type="danger" @click="handlerRemove(scope.row)">删除</el-button>
        </div>
      </template>

    </x-table>

    <el-dialog v-model="show" width="30%" center :destroy-on-close="true" ref="modalRef" @close="handleClose">
      <div class="dialog">
        <div class="title">{{ data.id !== undefined ? '编辑' : '添加' }}项目</div>

        <!-- 表单 -->
        <el-form ref="form" :model="data">
          <template v-for="item in forms" :key="item.field">
            <div class="item">
              <div>{{ item.label }}</div>
              <!-- 表单输入框 -->
              <el-form-item :prop="item.field">

                <!-- 选项 -->
                <template v-if="item.type === 'select'">
                  <el-select :placeholder="item.placeholder" v-model="data[`${item.field}`]" v-bind="item.otherOption"
                    style="width: 100%;">

                    <template v-for="option in item.options" :key="option.value">
                      <el-option :value="option.label">
                        {{ option.label }}
                      </el-option>
                    </template>

                  </el-select>
                </template>

                <!-- 输入框 -->
                <template v-else>
                  <el-input v-model="data[`${item.field}`]" :type="item.type" :placeholder="item.placeholder"
                    size="large">
                  </el-input>
                </template>
              </el-form-item>
            </div>
          </template>
        </el-form>

        <div>
          <el-button @click="handleClose"> 取消 </el-button>
          <el-button @click="handleConfirm"> {{ data.id !== undefined ? '编辑' : '添加' }} </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding: 10px;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;


  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

}
</style>
