<script lang="ts" setup>

import { reactive, ref, onMounted } from 'vue'
import { ElMessageBox, ElDialog } from 'element-plus'

import { useLoginStore } from '@/stores/login/login'
import { IUpdateData } from '@/views/login/types'
import { IDialogItem } from '@/types'
import { createPassword } from '@/utils/string'
import storage, { Keys } from '@/utils/storage'

const show = ref<boolean>(false)
const name = ref<string>('')
const modalRef = ref<InstanceType<typeof ElDialog>>()


// 校验规则
 const rules = {
  oldPassword: [
    {
      required: true,
      message: '原密码是必填',
      trigger: 'blur'
    },
    {
      pattern: /^[a-zA-Z0-9]{5,}$/,
      message: '密码长度必须是5位以上',
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      message: '新密码是必填',
      trigger: 'blur'
    },
    {
      pattern: /^[a-zA-Z0-9]{5,}$/,
      message: '密码长度必须是5位以上',
      trigger: 'blur'
    }
  ]
}

// 修改密码
const data = reactive<IUpdateData>({
  // 旧密码
  oldPassword: '',
  // 新密码
  password: ''
})

/**
 * 表单数据
 */
 let forms: IDialogItem[] = [
  {
    type: 'input',
    field: 'oldPassword',
    label: '原密码',
    placeholder: '请输入原密码'
  },
  {
    type: 'input',
    field: 'password',
    label: '新密码',
    placeholder: '请输入新密码'
  },
]

const store = useLoginStore()

// 修改密码
const handleUpdate = () => {
  show.value = true
}
/**
 * 退出登录
 */
const handleLogout = () => {
  ElMessageBox.confirm(
    `您确定要退出登录？`,
    '温馨提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      center: true,
    }
  )
    .then(() => {
      store.logout()
    }).catch(() => {
      console.log('close')
    })
}
/**
 * 确认修改
 */
const handleConfirm = () => {
  // 获取帐号信息
  const account = storage.getCache(Keys.user).account

  // 加密密码
  const oldPassword = createPassword(data.oldPassword, account)
  const password = createPassword(data.password, account)

  // 设置密码
  data.oldPassword = oldPassword
  data.password = password

  // 发起请求
  store.update({ ...data })

  handleClose()
}

/**
 * 取消
 */
const handleClose = () => {
  show.value = false
  data.password = ''
  data.oldPassword = ''
}

onMounted(() => {
  name.value = storage.getCache(Keys.user).name
})

</script>

<template>
  <div class="header">
    <div></div>
    <div class="company">
      <img class="logo" src="../../assets/images/xyq_logo_2.jpg" alt="" />
      <div>鑫愈强记账管理系统</div>
    </div>

    <el-dropdown>
      <div class="name">{{ name }}</div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="handleUpdate">
            修改密码
          </el-dropdown-item>

          <el-dropdown-item @click="handleLogout">
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>


    <el-dialog v-model="show" width="30%" center :destroy-on-close="true" ref="modalRef" @close="handleClose">
      <div class="dialog">
        <div class="title">修改密码</div>

        <!-- 表单 -->
        <el-form ref="form" :model="data"  :rules="rules">
          <template v-for="item in forms" :key="item.field">
            <div class="item">
              <div>{{ item.label }}</div>
              <!-- 表单输入框 -->
              <el-form-item :prop="item.field">
                <!-- 输入框 -->
                <el-input v-model="data[`${item.field}`]" :type="item.type" :placeholder="item.placeholder"
                  :show-password="true" size="large">
                  </el-input>
              </el-form-item>
            </div>
          </template>
        </el-form>

        <div>
          <el-button @click="handleClose"> 取消 </el-button>
          <el-button @click="handleConfirm"> 修改 </el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-bottom-style: dashed;
  background-color: white;

  .company {
    display: flex;
    align-items: center;

    font-size: 22px;
    font-weight: 500;

    .logo {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .name {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
  }
}


.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;


  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
</style>
