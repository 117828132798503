import { ITable } from "@/components/table"

export const reviewTable: ITable = {
  propList: [
    { prop: 'employeeName', label: '提交人', minWidth: 80 },
    { prop: 'projectName', label: '所属项目', minWidth: 160 },
    { prop: 'items', label: '详情', minWidth: 160, slotName: 'items' },
    { prop: 'managerName', label: '项目经理', minWidth: 90 },
    { prop: 'managerTime', label: '项目经理审批', minWidth: 120 },
    { prop: 'generalName', label: '总经理', minWidth: 80 },
    { prop: 'generalTime', label: '总经理审批', minWidth: 120 },
    { prop: 'state', label: '审批状态', minWidth: 90, slotName: 'state' },
    { prop: 'level', label: '待审批等级', minWidth: 140, slotName: 'level' },
    { prop: 'createTime', label: '提交时间', minWidth: 130 },
    { prop: 'updateTime', label: '更新时间', minWidth: 130 }
  ],
  showIndexColumn: true,
  showSelectColumn: false,
  showFooter: true,
}
