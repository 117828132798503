import { defineStore } from 'pinia'
import {
  queryRequest,
  saveRequest,
  updateRequest,
  removeRequest,
  resignedRequest,
  queryGeneralRequest,
  IEmployeeQuery
} from '@/service/main/employee'
import { IEmployee } from '@/views/main/employee/types'
import { IOption } from '@/types'
import { IEmployeeData } from '@/views/main/employee/types'
import { ElMessage } from 'element-plus'

export const useEmployeeStore = defineStore('employee', {
  state: () => ({
    employees: [] as IEmployee[],
    total: 0,
    roles: [] as IOption[],
    gender: [
      {
        label: '女',
        value: '0'
      },
      {
        label: '男',
        value: '1'
      }
    ]
  }),
  actions: {
    /**
     * 查询员工
     * @param data 分页数据
     */
    async query(data: IEmployeeQuery) {
      // 查询数据
      const result = await queryRequest(data)
      this.employees = result.data as IEmployee[]
      this.total = result.total
    },

    /**
     * 添加员工
     * @param data 添加数据
     */
    async save(data: IEmployeeData) {
      // 发起请求
      saveRequest(data)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 更新员工
     * @param data 修改数据
     */
    async update(data: IEmployeeData) {
      // 发起请求
      updateRequest(data)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 删除员工
     * @param id 员工id
     */
    remove(id: string) {
      // 发起请求
      removeRequest(id)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 员工离职
     * @param id 员工id
     */
    resigned(id: string) {
      // 发起请求
      resignedRequest(id)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 查询总经理
     */
    async queryGeneral() {
      if (this.roles.length > 0) {
        this.roles = this.roles.map((role) => role)
        return
      }

      // 查询数据
      const result = await queryGeneralRequest()

      const options = [] as IOption[]
      const employee = { label: '员工', value: '2' }
      const manager = { label: '项目经理', value: '1' }
      options.push(employee)
      options.push(manager)

      if (result.data.length === 0) {
        const general = { label: '总经理', value: '0' }
        options.unshift(general)
      }

      this.roles = options
    }
  }
})
