<script lang="ts" setup>
import { ElDialog, ElMessageBox } from 'element-plus'
import { ref, reactive, computed, watch, onMounted } from 'vue'

import xTable from '@/components/table'

import { useTypeStore } from '@/stores/type/type'
import { typeTable } from './config/type-config'
import { IDialogItem } from '@/types'
import { ITypeData } from './types'
import { ITypeObject } from '@/service/type/type'

const show = ref<boolean>(false)
const modalRef = ref<InstanceType<typeof ElDialog>>()

// 添加/更新项目
const data = reactive<ITypeData>({
  // 类型id
  id: undefined,
  // 类型名称
  name: '',
  // 排序【越小越前面】
  sort: 0,
  // 费用类型单位
  unit: '',
  // 是否是收入 0：否 1：是
  isIncome: '',
  // 描述信息
  description: ''
})

/**
 * 表单数据
 */
const forms: IDialogItem[] = [
  {
    type: 'input',
    field: 'name',
    label: '类型名称',
    placeholder: '请输入类型'
  },
  {
    type: 'number',
    field: 'sort',
    label: '排序',
    placeholder: '请输入排序【越小越前面】'
  },
  {
    type: 'textarea',
    field: 'description',
    label: '描述信息',
    placeholder: '请输入描述信息'
  },
  {
    type: 'input',
    field: 'unit',
    label: '类型单位',
    placeholder: '请输入单位(元/次/车...)'
  },
  {
    type: 'radio',
    field: 'isIncome',
    label: '收入/支出',
    placeholder: '请选择收入/支出',
    options: [{
      label: '收入',
      value: '1'
    }, {
      label: '支出',
      value: '0'
    }]
  }
]

/**
 * 添加项目
 */
const handleCreate = () => {
  show.value = true
}

/**
 * 更新
 * @param row 更新的数据
 */
const handleUpdate = (row: ITypeObject) => {
  const { id, name, unit, sort, isIncome, description } = row
  data.id = id
  data.sort = sort
  data.name = name
  data.unit = unit
  data.description = description
  data.isIncome = isIncome ? '1' : '0'
  handleCreate()
}

const handleRemove = (row: ITypeObject) => {
  ElMessageBox.confirm(
    `您确定要删除 ${row.name} 吗？`,
    '温馨提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      center: true,
    }
  )
    .then(() => {
      store.remove(row.id)
    }).catch(() => {
      console.log('close')
    })
}

/**
 * 确认
 */
const handleConfirm = () => {

  const { id, name, unit, sort, isIncome, description } = data

  const income = isIncome === '0' ? false : true
  if (id !== undefined) {
    store.update({ id, name, unit, sort, isIncome: income, description })

  } else {
    store.save({ name, unit, sort, isIncome: income, description })
  }

  handleClose()
}

/**
 * 取消
 */
const handleClose = () => {
  show.value = false
  data.id = undefined
  data.name = ''
  data.unit = ''
  data.sort = 0
  data.description = ''
  data.isIncome = ''
}

/**
 * 分页数据
 */
const pageInfo = ref({
  page: 1,
  limit: 10
})

watch(pageInfo, () => {
  store.query({ ...pageInfo.value })
})

// 计算属性
const store = useTypeStore()
const types = computed(() => store.types)
const total = computed(() => store.total)

// 挂载完成 请求数据
onMounted(() => {
  store.query({ ...pageInfo.value })
})

</script>

<template>
  <div>
    <x-table :data-list="types" :total-count="total" v-model:pageInfo="pageInfo" v-bind="typeTable">

      <template #header>
        <div class="header">
          <el-button @click="handleCreate">添加类型</el-button>
        </div>
      </template>

      <template #description="scope">
        <el-input v-model="scope.row.description" disabled autosize type="textarea" resize="none" size="large">
        </el-input>
      </template>

      <template #isIncome="scope">
        <!-- 是否是收入 0：否 1：是 -->
        <div v-if="scope.row.isIncome == false" style="color: red;">
          支出
        </div>
        <div v-else style="color: green;">
          收入
        </div>
      </template>

      <template #handle="scope">
        <div>
          <el-button @click="handleUpdate(scope.row)">编辑</el-button>
          <el-button type="danger" @click="handleRemove(scope.row)">删除</el-button>
        </div>
      </template>

    </x-table>

    <el-dialog v-model="show" width="40%" center :destroy-on-close="true" ref="modalRef" @close="handleClose">
      <div class="dialog">
        <div class="title">{{ data.id !== undefined ? '编辑' : '添加' }}类型</div>

        <!-- 表单 -->
        <el-form ref="form" :model="data">
          <template v-for="item in forms" :key="item.field">
            <div class="item">
              <div>{{ item.label }}</div>
              <!-- 表单输入框 -->
              <el-form-item :prop="item.field">

                <!-- radio -->
                <template v-if="item.type === 'radio'">
                  <el-radio-group v-model="data[`${item.field}`]">
                    <template v-for="(option) in item.options" :key="option.value">
                      <el-radio :label="option.value">{{ option.label }}</el-radio>
                    </template>
                  </el-radio-group>
                </template>

                <!-- 输入框 -->
                <template v-else>
                  <el-input v-model="data[`${item.field}`]" :type="item.type" :placeholder="item.placeholder"
                    size="large">
                  </el-input>
                </template>
              </el-form-item>
            </div>
          </template>
        </el-form>

        <div>
          <el-button @click="handleClose"> 取消 </el-button>
          <el-button @click="handleConfirm"> {{ data.id !== undefined ? '编辑' : '添加' }} </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.header {
  padding: 10px;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;


  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

}
</style>
