import { ITable } from "@/components/table"

export const projectTable: ITable = {
  propList: [
    { prop: 'name', label: '项目名称', minWidth: 160},
    { prop: 'managerName', label: '项目经理', minWidth: 120},
    { prop: 'sort', label: '排序', minWidth: 80},
    { prop: 'createTime', label: '创建时间', minWidth: 130 },
    { prop: 'updateTime', label: '更新时间', minWidth: 130 },
    { label: '操作', minWidth: 180, slotName: 'handle' }
  ],
  showIndexColumn: true,
  showSelectColumn: false,
  showFooter: true,
}
