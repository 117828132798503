import { createApp } from 'vue'
import App from './App.vue'

import '@/assets/css/index.css'

import register from './global'
import router from './router'
import { setup } from './stores'

const app = createApp(App)
setup(app)
.use(register)
.use(router)
.mount('#app')
