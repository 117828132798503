import { defineStore } from 'pinia'

import {
  ITypeObject,
  ITypeQuery,
  queryRequest,
  saveRequest,
  updateRequest,
  removeRequest
} from '@/service/type/type'
import { IRequestData } from '@/views/main/type/types'
import { ElMessage } from 'element-plus'

export const useTypeStore = defineStore('type', {
  state: () => ({
    types: [] as ITypeObject[],
    total: 0
  }),
  actions: {
    /**
     * 查询类型列表
     * @param data 分页数据
     */
    query(data: ITypeQuery) {
      // 发起请求
      queryRequest(data)
        .then((result) => {
          // 保存数据
          this.types = result.data as ITypeObject[]
          this.total = result.total
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 添加类型
     * @param data 添加数据
     */
    save(data: IRequestData) {
      // 发起请求
      saveRequest(data)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 更新类型
     * @param data 修改数据
     */
    update(data: IRequestData) {
      // 发起请求
      updateRequest(data)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    /**
     * 删除类型
     */
    remove(id: string) {
      // 发起请求
      removeRequest(id)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
})
