<script lang="ts" setup>

import router from '@/router'
import { computed, onMounted } from 'vue'
import { useHomeStore } from '@/stores/home/home'

import { xyqRouter } from '@/utils/constants'

const handleProjects = () => {
  router.push(xyqRouter.project)
}

const handleTypes = () => {
  router.push(xyqRouter.type)
}

const handleEmployeees = () => {
  router.push(xyqRouter.employee)
}

const store = useHomeStore()
const counter = computed(() => store.counter)

onMounted(() => {
  store.homeCounter()
})

</script>

<template>
  <div class="home">
    <h2 style="margin-top: 30px;">欢迎使用鑫愈强记账管理系统</h2>
    <div class="counter">

      <div class="row">

        <div class="item" @click="handleProjects">
          <div class="count">{{ counter.projects }}</div>
          <div>当前服务项目</div>
        </div>

        <div class="item" @click="handleTypes">
          <div class="count">{{ counter.types }}</div>
          <div>项目收支类型</div>
        </div>
        <div class="item" @click="handleEmployeees">
          <div class="count">{{ counter.employees }}</div>
          <div>企业员工</div>
        </div>
      </div>

      <div class="row">
        <div class="item">
          <div class="count">{{ counter.mreviews }}</div>
          <div>待项目经理审核</div>
        </div>
        <div class="item">
          <div class="count">{{ counter.greviews }}</div>
          <div>待总经理审核</div>
        </div>
      </div>


    </div>
  </div>
</template>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;

  .counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 600px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;
      width: 100%;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: pointer;

        .count {
          font-size: 24px;
          font-weight: 700;
          color: #015AAE;
        }
      }
    }
  }
}
</style>
