import { XYQRequest } from '..'
import { path } from '@/utils/constants'
import { IRequestData } from '@/views/main/type/types'

/**
 * 分页
 */
export interface ITypeQuery {
  // 页码
  page: number
  // 每页数量
  limit: number
}

/**
 * 类型
 */
export interface ITypeObject {
  // 类型id
  id: string
  // 类型名称
  name: string
  // 排序【越小越前面】
  sort: number
  // 费用类型单位
  unit: string
  // 是否是收入 0：否 1：是
  isIncome: boolean
  // 描述信息
  description: string
  // // 创建时间
  createTime: Date
  // 更新时间
  updateTime: Date
}

/**
 * 查询项目
 * @param data 分页数据
 * @returns 项目列表
 */
export function queryRequest(data: ITypeQuery) {
  return XYQRequest.get<ITypeObject[]>({
    url: path.type,
    params: data
  })
}

/**
 * 添加项目
 * @param data 添加数据
 */
export function saveRequest(data: IRequestData) {
  return XYQRequest.post({
    url: path.type,
    data: data
  })
}

/**
 * 更新项目
 * @param data 更新数据
 */
export function updateRequest(data: IRequestData) {
  return XYQRequest.put({
    url: path.type,
    data: data
  })
}


/**
 * 删除类型
 * @param data 更新数据
 */
export function removeRequest(id: string) {
  return XYQRequest.delete({
    url: path.type,
    params: { id }
  })
}
