import Request from './request/request'
import { API_BASE_URL, TIME_OUT } from './request/config'

const headers = () => {
  const data = { 'content-type': 'application/json' }
  return data
}

export const XYQRequest = new Request({
  baseURL: API_BASE_URL,
  timeout: TIME_OUT,
  headers: headers()
})
