
import { XYQRequest } from ".."
import { path } from "@/utils/constants"
import { IEmployee } from "@/views/main/employee/types"
import { IEmployeeData } from "@/views/main/employee/types"

/**
 * 分页
 */
export interface IEmployeeQuery {
  // 页码
  page: number
  // 每页数量
  limit: number
}

/**
 * 查询员工
 * @param data 分页数据
 * @returns 员工列表
 */
export function queryRequest(data: IEmployeeQuery) {
  return XYQRequest.get<IEmployee[]>({
    url: path.employee,
    params: data
  })
}

/**
 * 添加员工
 * @param data 添加数据
 */
export function saveRequest(data: IEmployeeData) {
  return XYQRequest.post({
    url: path.employee,
    data: data
  })
}

/**
 * 更新员工
 * @param data 更新数据
 */
export function updateRequest(data: IEmployeeData) {
  return XYQRequest.put({
    url: path.employee,
    data: data
  })
}

/**
 * 删除员工
 * @param id 员工id
 */
export function removeRequest(id: string) {
  return XYQRequest.delete({
    url: path.employee,
    params: { id }
  })
}

/**
 * 删除员工
 * @param id 员工id
 */
export function resignedRequest(id: string) {
  return XYQRequest.put({
    url: path.employee,
    data: { id, resigned: true }
  })
}

/**
 * 查询总经理
 */
export function queryGeneralRequest() {
  return XYQRequest.get<IEmployee[]>({
    url: path.employee + '/0'
  })
}
