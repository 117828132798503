
import { XYQRequest } from ".."
import { ILogon } from "./types"
import { ILoginData, IUpdateData } from "@/views/login/types"
import { path } from "@/utils/constants"

/**
 * 登录请求
 * @param data 登录数据
 * @returns token & 用户信息
 */
export function loginRequest(data: ILoginData) {
  return XYQRequest.post<ILogon>({
    url: path.login,
    data: data
  })
}

/**
 * 登出请求
 */
export function logoutRequest() {
  return XYQRequest.post({
    url: path.logout,
  })
}

/**
 * 修改密码请求
 */
export function updateRequest(data: IUpdateData) {
  return XYQRequest.put({
    url: path.update,
    data: data
  })
}
