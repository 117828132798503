
import { Md5 } from "ts-md5"

/**
 * 生成密码
 * @param password 明文密码
 * @param salt 盐
 * @returns 密码
 */
export const createPassword = (password: string, salt: string) => {
  const passwordText = "xyq_finance_" + password + "_&_" + salt + "_xyq_sz_" + salt;
  return Md5.hashStr(passwordText)
}
