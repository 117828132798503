<script lang="ts" setup>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus'
import { rules } from './config/login-config'
import { ILoginData } from './types/index'
import { IFormItem } from '@/types'
// import router from '@/router'
// import { xyqRouter } from '@/utils/constants'
import { reactive, ref, onMounted } from 'vue'

import { useLoginStore } from '@/stores/login/login'

import { createPassword } from '@/utils/string'

// 登录数据
const data = reactive<ILoginData>({
  account: '',
  password: '',
  captcha: ''
})

/**
 * 表单数据
 */
const forms: IFormItem[] = [
  {
    type: 'input',
    icon: 'User',
    key: 'account',
    placeholder: '请输入帐号'
  },
  {
    type: 'password',
    icon: 'Lock',
    key: 'password',
    placeholder: '请输入密码'
  }
  // ,
  // {
  //   type: 'input',
  //   icon: 'Lock',
  //   key: 'captcha',
  //   placeholder: '请输入验证码'
  // }
]

// 表单
const form = ref<InstanceType<typeof ElForm>>()
// 验证码链接
// let captcha = ref<string>('')

const handleLogin = () => {
  if (data.account.length <= 0) {
    ElMessage.error({ message: '请输入帐号' })
    return
  }

  if (data.password.length <= 0) {
    ElMessage.error({ message: '请输入密码' })
    return
  }
  // 校验
  const valid = form.value?.validate()
  if (!valid) {
    // 校验不过
    return
  }

  const store = useLoginStore()

  // 加密
  const password = createPassword(data.password, data.account)

  // 登录
  store.login({ ...data, password })
}

// const handleRegister = () => {
  // router.push(xyqRouter.register)
// }

/**
 * 加载验证码
 */
// const reloadCaptcha = () => {
//   captcha.value = API_BASE_URL + path.captcha + '?' + Math.random()
// }

/**
 * 挂载完成
 */
onMounted(() => {
  // reloadCaptcha()
})
</script>

<template>
  <div class="content">
    <div class="panel">
      <img class="logo" src="../../assets/images/xyq_logo.jpg" alt="" />
      <div class="info">
        <!-- 公司名称 -->
        <div class="company">鑫愈强记账管理系统</div>

        <!-- 表单 -->
        <el-form ref="form" :model="data" :rules="rules">
          <template v-for="item in forms" :key="item.key">
            <div class="item">
              <!-- 验证码图标 -->
              <!-- <img class="image" v-if="item.key === 'captcha'" :src="captcha" @click="reloadCaptcha" alt=""> -->

              <!-- 表单输入框 -->
              <el-form-item :prop="item.key">
                <el-input
                  v-model="data[`${item.key}`]"
                  :type="item.type"
                  :prefix-icon="item.icon"
                  :placeholder="item.placeholder"
                  :show-password="item.type === 'password'"
                  size="large"
                  style="width: 270px"
                >
                </el-input>
              </el-form-item>
            </div>
          </template>
        </el-form>

        <!-- 登录按钮 -->
        <div class="action">
          <el-button class="button" @click="handleLogin">登录</el-button>
          <!-- <el-button size="small" @click="handleRegister">注册</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.content {
  display: flex;
  justify-content: center;
  height: 100%;

  .panel {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 150px;
    width: 800px;
    height: 300px;

    .info {
      display: flex;
      flex-direction: column;

      .company {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 60px;
      }

      .item {
        display: flex;
        justify-content: end;

        .image {
          width: 80px;
          height: 38px;
          margin-right: 16px;
        }
      }

      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;

        .button {
          // width: 200px;
          width: 100%;
          height: 100%;
        }
      }
    }

    .logo {
      width: 300px;
      height: 300px;
      object-fit: fill;
      margin-right: 100px;
    }
  }
}
</style>
