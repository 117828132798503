import { XYQRequest } from '..'
import { path } from '@/utils/constants'
import { IEmployee } from '@/views/main/employee/types'
import { IProjectData } from '@/views/main/project/types'

/**
 * 分页
 */
export interface IProjectQuery {
  // 页码
  page: number
  // 每页数量
  limit: number
}

/**
 * 项目
 */
export interface IProject {
  // 项目id
  id: string
  // 项目名称
  name: string
  // 项目经理id
  managerId: string
  // 项目经理名称
  managerName: string
  // 排序
  sort: number
  // 创建时间
  createTime: Date
  // 更新时间
  updateTime: Date
}

/**
 * 查询项目
 * @param data 分页数据
 * @returns 项目列表
 */
export function queryRequest(data: IProjectQuery) {
  return XYQRequest.get<IProject[]>({
    url: path.project,
    params: data
  })
}

/**
 * 添加项目
 * @param data 添加数据
 */
export function saveRequest(data: IProjectData) {
  return XYQRequest.post({
    url: path.project,
    data: data
  })
}

/**
 * 更新项目
 * @param data 更新数据
 */
export function updateRequest(data: IProjectData) {
  return XYQRequest.put({
    url: path.project,
    data: data
  })
}

/**
 * 删除项目
 */
export function removeRequest(id: string) {
  return XYQRequest.delete({
    url: path.project,
    params: { id }
  })
}


/**
 * 查询项目经理
 * @param data 分页数据
 * @returns 项目经理列表
 */
export function queryManagerRequest() {
  return XYQRequest.get<IEmployee[]>({
    url: path.employee + '/1'
  })
}
