<script lang="ts" setup>

import { reactive, ref, watch, computed, onMounted } from 'vue'
import xTable from '@/components/table'
import { employeeTable } from './config/employee-config'
import { useEmployeeStore } from '@/stores/employee/employee'
import { IEmployee } from '@/views/main/employee/types'
import { ElDialog, ElMessageBox } from 'element-plus'
import { IEmployeeData } from './types'
import { IDialogItem } from '@/types'

const show = ref<boolean>(false)
const modalRef = ref<InstanceType<typeof ElDialog>>()

// 添加/更新项目
const data = reactive<IEmployeeData>({
  // 员工id
  id: undefined,
  // 手机号
  phone: '',
  // 员工名称
  name: '',
  // 角色
  role: '',
  // 性别
  gender: ''
})

/**
 * 表单数据
 */
let forms: IDialogItem[] = [
  {
    type: 'input',
    field: 'name',
    label: '员工姓名',
    placeholder: '请输入员工姓名'
  },
  {
    type: 'select',
    field: 'role',
    label: '角色',
    placeholder: '请选择角色'
  },
  {
    type: 'select',
    field: 'gender',
    label: '性别',
    placeholder: '请选性别'
  }
]

const pageInfo = ref({
  page: 1,
  limit: 10
})

watch(pageInfo, () => {
  store.query({ ...pageInfo.value })
})

/**
 * 添加员工
 */
const handleCreate = () => {

  if (0 == forms.filter((form) => form.field === 'phone').length) {
    forms.unshift({
      type: 'number',
      field: 'phone',
      label: '手机号',
      placeholder: '请输入手机号'
    })
  }

  store.queryGeneral()
  show.value = true
}

/**
 * 确认
 */
const handleConfirm = () => {
  let roles = store.roles
  roles = roles.filter((role) => role.label === data.role)
  if (roles.length <= 0) {
    return
  }
  data.role = roles[0].value

  let genders = store.gender
  genders = genders.filter((gender) => gender.label === data.gender)
  if (genders.length <= 0) {
    data.gender = '2'
  } else {
    data.gender = genders[0].value
  }

  const { id, role, phone, name, gender } = data
  if (id !== undefined) {
    store.update({ id, role, phone, name, gender })

  } else {
    store.save({ role, phone, name, gender })
  }

  handleClose()
}

/**
 * 更新
 * @param row 更新的数据
 */
const handleUpdate = (row: IEmployee) => {
  const { id, name, role, gender } = row

  data.id = id
  data.name = name

  let options = store.roles.filter((option) => option.value === role + '')
  if (options.length <= 0) {
    data.role = ''
  } else {
    data.role = options[0].label
  }

  options = store.gender.filter((option) => option.value === gender + '')
  if (options.length <= 0) {
    data.gender = '未知'
  } else {
    data.gender = options[0].label
  }

  forms = forms.filter((form) => form.field !== 'phone')

  store.queryGeneral()
  show.value = true
}

/**
 * 离职
 * @param row 员工信息
 */
const handleResigned = (row: IEmployee) => {
  var role = '职员'
  if (1 == row.role) {
    role = '项目经理'
  }

  ElMessageBox.confirm(
    `您确定 ${role} ${row.name} 已离职吗？`,
    '温馨提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      center: true,
    }
  )
    .then(() => {
      store.resigned(row.id)
    }).catch(() => {
      console.log('close')
    })
}

/**
 * 删除
 * @param row 员工信息
 */
const handleRemove = (row: IEmployee) => {
  var role = '职员'
  if (1 == row.role) {
    role = '项目经理'
  }
  const message = `您确定要删除 ${role} ${row.name} 吗？`

  ElMessageBox.confirm(
    message,
    '温馨提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      center: true,
    }
  )
    .then(() => {
      store.remove(row.id)
    }).catch(() => {
      console.log('close')
    })
}

/**
 * 取消
 */
const handleClose = () => {
  show.value = false
  data.id = undefined
  data.phone = ''
  data.name = '' // e10adc3949ba59abbe56e057f20f883e
  data.role = ''
}

const store = useEmployeeStore()
const employees = computed(() => store.employees)
const total = computed(() => store.total)

// 订阅事件
store.$subscribe((_mutation, state) => {
  // 添加选项
  forms.forEach((form) => {
    if (form.field === 'role') {
      form.options = state.roles

    } else if (form.field === 'gender') {
      form.options = store.gender
    }
  })
})

// 挂载完成 请求数据
onMounted(() => {
  store.query({ ...pageInfo.value })
})

</script>

<template>
  <div>
    <x-table :data-list="employees" :total-count="total" v-model:pageInfo="pageInfo" v-bind="employeeTable">
      <template #header>
        <div class="header">
          <el-button @click="handleCreate">添加员工</el-button>
        </div>
      </template>

      <template #gender="scope">
        <!-- 性别 0：女 1：男 2:未知 -->
        <div v-if="scope.row.gender == 0">
          女
        </div>
        <div v-else-if="scope.row.gender == 1">
          男
        </div>
        <div v-else>
          未知
        </div>
      </template>

      <template #role="scope">
        <!-- 员工角色 0：总经理 1：项目经理 2： 员工 -->
        <div v-if="scope.row.role == 0">
          总经理
        </div>
        <div v-else-if="scope.row.role == 1">
          项目经理
        </div>
        <div v-else>
          员工
        </div>
      </template>

      <template #resigned="scope">
        <!-- 是否离职 0：否  1：是 -->
        <div v-if="scope.row.resigned == false">
          在职
        </div>
        <div v-else style="color: red;">
          离职
        </div>
      </template>

      <template #handle="scope">
        <div v-if="scope.row.role != 0">
          <el-button @click="handleUpdate(scope.row)">编辑</el-button>
          <el-button v-if="scope.row.resigned == false" type="warning" @click="handleResigned(scope.row)">离职</el-button>
          <el-button type="danger" @click="handleRemove(scope.row)">删除</el-button>
        </div>
      </template>
    </x-table>

    <el-dialog v-model="show" width="30%" center :destroy-on-close="true" ref="modalRef" @close="handleClose">
      <div class="dialog">
        <div class="title">{{ data.id !== undefined ? '编辑' : '添加' }}员工</div>

        <!-- 表单 -->
        <el-form ref="form" :model="data">
          <template v-for="item in forms" :key="item.field">
            <div class="item">
              <div>{{ item.label }}</div>
              <!-- 表单输入框 -->
              <el-form-item :prop="item.field">

                <!-- 选项 -->
                <template v-if="item.type === 'select'">
                  <el-select :placeholder="item.placeholder" v-model="data[`${item.field}`]" v-bind="item.otherOption"
                    style="width: 100%;">

                    <template v-for="option in item.options" :key="option.value">
                      <el-option :value="option.label">
                        {{ option.label }}
                      </el-option>
                    </template>

                  </el-select>
                </template>

                <!-- 输入框 -->
                <template v-else>
                  <el-input v-model="data[`${item.field}`]" :type="item.type" :placeholder="item.placeholder"
                    size="large">
                  </el-input>
                </template>
              </el-form-item>
            </div>
          </template>
        </el-form>

        <div>
          <el-button @click="handleClose"> 取消 </el-button>
          <el-button @click="handleConfirm"> {{ data.id !== undefined ? '编辑' : '添加' }} </el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<style lang="less" scoped>
.header {
  padding: 10px;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;


  .title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }
}
</style>
