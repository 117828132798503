import { defineStore } from 'pinia'
import { IEmployee } from '@/views/main/employee/types'
import {
  queryRequest,
  IProjectQuery,
  IProject,
  queryManagerRequest,
  saveRequest,
  removeRequest,
  updateRequest
} from '@/service/main/project'

import { IProjectData } from '@/views/main/project/types'
import { ElMessage } from 'element-plus'

export const useProjectStore = defineStore('project', {
  state: () => ({
    projects: [] as IProject[],
    managers: [] as IEmployee[],
    total: 0
  }),
  actions: {
    /**
     * 查询项目列表
     * @param data 分页数据
     */
    async query(data: IProjectQuery) {
      // 发起请求
      queryRequest(data)
        .then((result) => {
          // 保存数据
          this.projects = result.data as IProject[]
          this.total = result.total
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 添加项目
     * @param data 添加数据
     */
    save(data: IProjectData) {
      // 发起请求
      saveRequest(data)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /**
     * 更新项目
     * @param data 修改数据
     */
    update(data: IProjectData) {
      // 发起请求
      updateRequest(data)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    /**
     * 删除类型
     */
    remove(id: string) {
      // 发起请求
      removeRequest(id)
        .then((result) => {
          ElMessage.success({ message: result.message })
          this.query({ page: 1, limit: 10 })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    /**
     * 查询项目经理
     * @param data 分页数据
     * @returns 项目经理列表
     */

    async queryManager() {
      // 发起请求
      const result = await queryManagerRequest()
      // 保存数据
      this.managers = result.data as IEmployee[]
    }
  }
})
