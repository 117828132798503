import { App } from 'vue'

import {
  User,
  Lock,
  HomeFilled,
  List,
  PictureFilled,
  Search,
  Refresh,
  Plus
   } from '@element-plus/icons-vue'

const icons = [
  User,
  Lock,
  HomeFilled,
  List,
  PictureFilled,
  Search,
  Refresh,
  Plus
]

// 注册icon
function useIcon(app: App): void {
  // 遍历组件 注册
  for (const icon of icons) {
    app.component(icon.name ?? '', icon)
  }
}

// 导出注册方法
export default useIcon
