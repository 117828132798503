
import { XYQRequest } from ".."
import { path } from "@/utils/constants"

/**
 * 分页
 */
export interface IReviewQuery {
  // 页码
  page: number
  // 每页数量
  limit: number
  // 日期 2024-08-01,2024-08-31
  dates?: string
  // 员工名称
  name?: string
  // 项目名称
  project?: string
}

export interface IDetail {
  // 类型名称
  name: string,
  // 凭证
  certificate: string,
  // 金额
  amount: number,
  // 是否是收入 0：否 1：是
  isIncome: boolean
  // 是否需要计算单价 0：否 1：是
  need: boolean,
  // 总价
  total: number,
  // 单位
  unit: string
}

/**
 * 审批记录
 */
export interface IReview {
  // 记录id
  id: string
  // 提交者
  employeeName: string
  // 项目名称
  projectName: string
  // 项目经理名称
  managerName: string
  // 项目经理审批时间
  managerTime: Date
  // generalName
  generalName: string
  // 总经理审批时间
  generalTime: Date
  // 审批状态 0：同意 1：拒绝 2：待审批 3：撤回
  state: number
  // 审批等级 0：总经理审批 1: 项目经理审批
  level: number
  // 详情
  items: IDetail[]
}


/**
 * 查询项目
 * @param data 分页数据
 * @returns 项目列表
 */
export function queryRequest(data: IReviewQuery) {
  return XYQRequest.get<IReview[]>({
    url: path.review,
    params: data
  })
}
