import { ITable } from "@/components/table"

export const typeTable: ITable = {
  propList: [
    { prop: 'name', label: '类型', minWidth: 160 },
    { prop: 'description', label: '描述信息', minWidth: 200, slotName: 'description' },
    { prop: 'unit', label: '费用单位', minWidth: 80 },
    { prop: 'isIncome', label: '收/支', minWidth: 120, slotName: 'isIncome' },
    { prop: 'sort', label: '排序', minWidth: 80},
    { prop: 'createTime', label: '创建时间', minWidth: 130 },
    { prop: 'updateTime', label: '更新时间', minWidth: 130 },
    { label: '操作', minWidth: 180, slotName: 'handle' }
  ],
  showIndexColumn: true,
  showSelectColumn: false,
  showFooter: true,
}
