<script lang="ts" setup>

import { ref } from 'vue'
import router from '@/router'
import { useRoute } from 'vue-router'

import { IMenu } from '../menu/types'
import { xyqRouter } from '@/utils/constants'

// 当前路径
const activePath = useRoute().path
// 默认选中菜单
const active = ref<number>(100)

// 菜单
const menus: IMenu[] = [
  {
    id: 100,
    title: '首页',
    path: xyqRouter.home
  },
  {
    id: 200,
    title: '审批',
    path: xyqRouter.review
  },
  {
    id: 300,
    title: '项目',
    path: xyqRouter.project
  },
  {
    id: 400,
    title: '员工',
    path: xyqRouter.employee
  },
  {
    id: 500,
    title: '类型',
    path: xyqRouter.type
  }
]

// 映射路径
const mapActivePath = (menus: IMenu[]) => {
  menus.forEach((menu) => {
    if (menu.path === activePath) {
      active.value = menu.id
    }
  })
}

// 调用方法
mapActivePath(menus)

const handleItem = (path: string) => {
  router.push(path)
}

const route = useRoute()

router.afterEach(() => {
  menus.forEach((menu) => {
    if (menu.path === route.path) {
      active.value = menu.id
    }
  })
})

</script>

<template>
  <div>
    <el-menu text-color="#fff" active-text-color="#ffd04b" background-color="#515A6E" :default-active="active + ''"
      :unique-opened="true">
      <template v-for="menu in menus" :key="menu.id + ''">
        <el-menu-item :index="menu.id + ''" @click="handleItem(menu.path)">
          <span>{{ menu.title }}</span>
        </el-menu-item>

      </template>
    </el-menu>
  </div>
</template>

<style lang="less" scoped></style>
